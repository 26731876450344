import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import Header from "./header";
import Footer from "./footer";

function BlogLayout({ children }) {
  return (
    <div className='flex flex-col min-h-screen font-sans bg-white text-gray-900'>
      <Header />
      <nav className='pt-4 md:pt-8 xxl:pt-16 px-8 lg:px-16 xxl:px-32'>
        <h1 className='mb-6 text-5xl'>blog & learnings.</h1>
        <ul className='flex mb-8'>
          <li className='mr-5'>
            <Link
              to='/blog'
              style={{ color: "#1A202C", fontSize: "1.25rem" }}
              activeStyle={{ borderBottom: "2px solid #1A202C" }}>
              All
            </Link>
          </li>
          <li className='mr-5'>
            <Link
              to='/blog/design'
              itemProp='url'
              style={{ color: "#1A202C", fontSize: "1.25rem" }}
              activeStyle={{ borderBottom: "2px solid #1A202C" }}>
              Design
            </Link>
          </li>
          <li>
            <Link
              to='/blog/code'
              itemProp='url'
              style={{ color: "#1A202C", fontSize: "1.25rem" }}
              activeStyle={{ borderBottom: "2px solid #1A202C" }}>
              Code
            </Link>
          </li>
        </ul>
      </nav>

      <main className='flex flex-wrap items-center justify-between  pb-4 md:pb-8 xxl:pb-16 px-8 lg:px-16 xxl:px-32'>
        {children}
      </main>

      <Footer />
    </div>
  );
}

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogLayout;

import React from "react";
import BlogLayout from "../../components/blog-layout";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Blog from "../../components/blog";

//This is design page
function Design({ data }) {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <BlogLayout>
      <ol style={{ listStyle: `none` }}>
        {posts.map((post) => {
          const title = post.frontmatter.title || post.fields.slug;

          return <Blog post={post} title={title} key={title} />;
        })}
      </ol>
    </BlogLayout>
  );
}

Design.propTypes = {
  data: PropTypes.object,
};

export default Design;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "Design" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
        }
      }
    }
  }
`;

import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

//Blog post
export default function Blog({ post, title }) {
  return (
    <li key={post.fields.slug} className='mb-12'>
      <article itemScope itemType='http://schema.org/Article'>
        <header>
          <h2>
            <Link to={post.fields.slug} itemProp='url'>
              <span itemProp='headline' className='text-3xl font-bold'>
                {title}
              </span>
            </Link>
          </h2>
        </header>
        <small style={{ textTransform: "uppercase" }}>
          {post.frontmatter.category.join("  •  ")}
        </small>
        <section className='mt-1'>
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp='description'
            className='text-lg font-normal'
          />
        </section>
      </article>
    </li>
  );
}

Blog.propTypes = {
  post: PropTypes.object,
  title: PropTypes.string,
};
